import { FC } from 'react';

interface RenderContentProps {
  currentTab: string;
}

const RenderContent: FC<RenderContentProps> = ({ currentTab }) => {
  if (currentTab === "Properties") {
    return (
      <div>Properties</div>
    );
  } else if (currentTab === "Bookings") {
    return (
      <div>Bookings</div>
    );
  } else if (currentTab === "Vehicles") {
    return (
      <div>Vehicles</div>
    );
  } else if (currentTab === "Booked Vehicles") {
    return (
      <div>Booked Vehicles</div>
    )
  } else {
    return (
      <div>Prices</div>
    )
  };
};

export default RenderContent
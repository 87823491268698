import { FC, useState } from 'react'
import IconSideBar from './IconSideBar';

export interface SideBarButtonType {
  id: number;
  title: string;
}

interface SideBarProps {
  setCurrentTab: (arg: string) => void;
}

const SideBar: FC<SideBarProps> = ({
  setCurrentTab,
}) => {

  const [showSideBar, setShowSideBar] = useState<boolean>(true);

  const sideBarButtons: SideBarButtonType[] = [
    {
      id: 0,
      title: "Properties",
    },
    {
      id: 1,
      title: "Bookings",
    },
    {
      id: 2,
      title: "Vehicles",
    },
    {
      id: 3,
      title: "Booked Vehicles",
    },
    {
      id: 4,
      title: "Prices",
    }
  ];

  return (
    <>
      {showSideBar ? (
        <div className='flex flex-col justify-start items-start gap-2 p-4 relative z-100 bg-white h-full'>
          {sideBarButtons.map((button) => {
            return (
              <IconSideBar 
                key={button.id} 
                data={button}
                setCurrentTab={setCurrentTab}  
              />
            )
          })}
        </div>
      ) : (
        null
      )}
    </>
  )
}

export default SideBar;
import { FC } from 'react';
import { SideBarButtonType } from './SideBar'

interface IconSideBarProps {
  data: SideBarButtonType;
  setCurrentTab: (arg: string) => void;
}

const IconSideBar: FC <IconSideBarProps>= ({
  data,
  setCurrentTab,
}) => {
  
  const {title} = data;

  const changeTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <div onClick={() => {changeTab(title)}}className='pl-4 py-2 rounded border-2 w-48 border-gray-200 hover:bg-gray-200 hover:text-cyan-600 cursor-pointer'>
      {title}
    </div>
  )
}

export default IconSideBar
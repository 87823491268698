import { FC, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SideBar from "./SideBar";
import RenderContent from "./RenderContent";

const Dashboard: FC = () => {

  const [currentTab, setCurrentTab] = useState<string>("Properties");

  return (
    <div className="flex flex-row justify-start items-center w-full h-5/6 relative overflow-hidden">
      <BgGlassmorphism />
      <SideBar 
        setCurrentTab={setCurrentTab}
      />
      <RenderContent currentTab={currentTab} />
    </div>
  )
}

export default Dashboard;
import React, { FC, ReactNode } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { PropertyType } from "types/property";
import HashLoader from "react-spinners/HashLoader";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  properties?: PropertyType[];
  gridClass?: string;
  heading?: ReactNode;
  loading?: boolean;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  properties,
  loading,
  gridClass = "",
  heading = "Featured Rentals",
  subHeading = "Popular places to stay that TryTry recommends!",
  headingIsCenter,
  tabs = ["Osaka"],
}) => {
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  console.log("child", properties);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div>
        {loading ? (
          <div className='flex flex-col justify-start items-center w-100 gap-10 py-20'>
            <div className='text-2xl'>Loading...</div>
            <HashLoader size={75} color="#115e59" />
          </div>
        ) : (
          <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
            {properties?.map((property) => renderCard(property))}
          </div>
        )}
        {/* {DEMO_DATA.map((stay) => renderCard(stay))} */}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
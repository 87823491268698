import { useState, useEffect} from 'react';
import { onSnapshot, DocumentData, QuerySnapshot} from "firebase/firestore";

// TODO - add firebase "where" query functionality 

function useFetch(targetCollection: any) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    onSnapshot(targetCollection, (snapshot: QuerySnapshot<DocumentData>) => {
      setData(
        snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data()
          };
        })
      );
      setLoading(false);
    })

  }, [targetCollection]);

  return {data, loading};
}

export default useFetch;
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  // apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  // authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  // measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
  apiKey: "AIzaSyAGyCwCIwxJTeEnzs7nZJ6-qM8YZzxdGpk",
  authDomain: "trytry-2b8ce.firebaseapp.com",
  projectId: "trytry-2b8ce",
  storageBucket: "trytry-2b8ce.appspot.com",
  messagingSenderId: "783743356157",
  appId: "1:783743356157:web:5ef0c2cab0ec4025214294",
  measurementId: "G-7RGCB864N4"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export const db = getFirestore(app);

export const storage = getStorage(app);
